'use client';
import { ComponentDecider } from '@grimme/buttery';
import theme from '@grimme/theme';
import { useSearchParams } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { Banner } from '../../components';
import { environment } from '../../environments';
import { useFetchAndProcessButterData, useGeolocation } from '../../hooks';

// TODO: this component should be replaced by buttery;
// we also need to change the structure in butter to make
// it up and running properly; currently, some butter
// data does not fit to the components taken from
// grimme component library;

//TODO: check if we consume a youtube video here and rather opt to use the embeded youtubevideo from nextJS instead of video
export const OnBoarding = () => {
  const queryParams = useSearchParams();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { countryCode } = useGeolocation();
  const preview = queryParams?.get('preview');
  const { data: pageData } = useFetchAndProcessButterData(
    language,
    'my-grimme-landingpage',
    !!preview,
  );
  const { body, buttery_components } = pageData?.fields ?? {};

  return (
    <>
      {body?.map((item, i) => {
        return <Banner key={i} {...item.fields} />;
      })}
      <div
        style={{
          backgroundColor: theme.palette.grey[100],
        }}
      >
        {buttery_components && (
          <ComponentDecider
            content={buttery_components}
            runtimeProps={{
              countryCode: countryCode,
              newsletterSubscriptionApiUrl: `${environment.newsletterSubscriptionApiUrl}?language=${language}`,
              t,
            }}
          />
        )}
      </div>
    </>
  );
};

export default OnBoarding;
